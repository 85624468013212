.header {
  background-color: var(--dark);
  padding: var(--padding);
}

.title {
  margin: 0;
  font-size: 1.618rem;
  color: var(--lightAccent);
  text-align: center;
  text-shadow: 0 0 0.5em var(--darkAccent);
}
