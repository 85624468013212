.tapper {
  background-color: var(--light);
  padding-bottom: 2em;
}

.tapper main {
  display: grid;
  grid-template-rows: 1fr 1fr;
  /* tap button is inverted on children*/
  margin: 0 auto;
  max-width: 30rem;
  background-color: var(--light);
}

@media only screen and (min-width: 768px) {
  .tapper main {
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr;
  }
}

.tapper h2,
.tapper h3,
.tapper p {
  color: var(--dark);
  text-align: center;
}

.tapper h2 {
  font-size: 1.5rem;
}
.tapper h3 {
  font-size: 1rem;
  margin: 1.5em 0 0;
}

.tapper p {
  font-size: 1rem;
}

.tapper__display {
  grid-row-start: 2;
}

@media only screen and (min-width: 768px) {
  .tapper__display h3 {
    margin: 0;
  }
  .tapper__display {
    min-width: 16em;
    grid-column-start: 1;
    grid-row-start: 1;
  }
}

.tapper__display p {
  margin: 0 1.25em 1.25em 1.25em;

  color: var(--dark);
  text-shadow: 1px 1px 0em var(--lightAccent), -1px -1px 0em var(--lightAccent),
    0 0 4px var(--light);
}

.tapper__display button {
  display: block;
  margin: 0 auto;
}

.tapper__button {
  --dimension: 8em;
  margin: 0 auto;
  cursor: pointer;
  width: var(--dimension);
  height: var(--dimension);
  outline: 0;
  border: 1px solid var(--dark);
  border-radius: 50%;
  box-shadow: var(--boxShadow);
  background: var(--lightAccent);
  color: var(--white);
  text-shadow: 0 0 4px var(--light);
  transition: transform 161ms;
  animation: var(--softIn);
}

.tapper__button:active {
  transform: scale(1.05);
  border-radius: 50%;
  outline: 0;
}

.tapper__button:focus {
  outline: 0;
  border-radius: 50%;
  box-shadow: var(--boxShadow), inset 0 0 0.25em rgba(0, 0, 0, 0.25);
}

@media screen and (min-width: 768px) {
  .tapper__button {
    grid-column-start: 2;
  }
}
